<template>
  <div :style= "`background: url(${require('@/assets/images/main_bg2.jpg')});background-repeat: no-repeat; background-size: 100% 100%`" >
     <b-container>
        <b-row class="justify-content-center align-items-center height-self-center">
            <b-col lg="5" md="12" class="align-self-center">
              <div class="sign-user_card " v-if="checkSerial === false">
                  <div class="sign-in-page-data">
                    <div class="sign-in-from w-100 m-auto">
                        <h3 class="mb-3 text-center">Serial Registration</h3>
                        <form class="mt-4">
                          <div class="form-group">
                            <input type="text" class='form-control mb-0' style='backgroundColor:#fff'
                                  id="emailInput" aria-describedby="emailHelp"
                                  v-model="serial" placeholder="시리얼 넘버를 입력해주세요" required>
                          </div>
                          <div class="sign-info">
                            <button type="button" class="btn-danger btn-hover" style="margin:0 auto;" @click="registrationSerial()">등록</button>
                          </div>
                        </form>
                    </div>
                  </div>
              </div>
              <div class="sign-user_card " v-if="checkSerial === true">
                  <div class="sign-in-page-data">
                    <div class="sign-in-from w-200 m-auto">
                        <div class="form-group">
                          <button type="button" class="btn btn-primary btn-hover" @click="movePage('ai-studio-front')">FRONT KIOSK</button>
                        </div>
                        <div class="form-group">
                          <button type="button" class="btn btn-danger btn-hover" @click="movePage('ai-studio-bottom')">BOTTOM KIOSK</button>
                        </div>
                        <div class="sign-info">
                          <button type="button" class="btn btn-info" @click="resetSerial()">시리얼 넘버 재등록</button>
                        </div>
                    </div>
                  </div>
              </div>
            </b-col>
        </b-row>
     </b-container>
  </div>
</template>
<!-- <style lang="scss">
  @import "../../assets/scss/frontend.scss";
</style> -->
<style>
/* .main-header{display : none} */
.justify-content-center{height: 100vh}
.btn{margin : 10px; padding: 20px; width: 300px; height: 100px; font-size : 24px}
#main-header{display: none;}
</style>

<script>

export default {
  name: 'SignIn1',
  components: { },
  data: () => ({
    serial: null,
    checkSerial: false
  }),
  mounted () {
    this.checkSerialName()
  },
  methods: {
    checkSerialName () {
      const serial = localStorage.getItem('serial')
      if (serial !== null) {
        this.checkSerial = true
      } else {
        this.checkSerial = false
      }
    },
    registrationSerial () {
      localStorage.setItem('serial', this.serial)
      this.checkSerial = true
    },
    movePage (url) {
      this.$router.replace(url)
    },
    resetSerial () {
      localStorage.removeItem('serial')
      this.serial = null
      this.checkSerial = false
    }
  }
}
</script>
